/************************************ this file include all pages style ******************************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Assistant" !important;
  text-decoration: none !important;
}

/* accent colors */
:root {
  --primary-color: #006aff;
  --secondary-color: #dfe1eb;
  --white: white;
  --default-bg-color: #f8f8f8;
  --default-color: black;
  --success-color: #2e7d32;
  --blue-color: #72edf2;
  --golden-color: #f1d600;
  --gray-color: gray;
  --red-color: red;
  --orange-color: orange;
  --glass-color: rgba(255, 255, 255, 0.55);
  --glass-border: rgba(209, 213, 219, 0.3);
  --transperent-black-color: rgba(20, 20, 20, 0.8);
  --error-color: #d32f2f;
  --header-color: rgba(255, 255, 255, 0.1);
  --label-color: rgba(0, 0, 0, 0.6);
}

body {
  background-color: var(--default-bg-color);
}

.primary-button {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--white) !important;
  border-radius: 12px !important;
}

.primary-button:hover {
  background: transparent !important;
  color: var(--primary-color) !important;
}

.success-button {
  background: var(--success-color) !important;
  border: 1px solid var(--success-color) !important;
  color: var(--white) !important;
  border-radius: 12px !important;
}

.success-button:hover {
  background: transparent !important;
  color: var(--success-color) !important;
}

.reset-button {
  background: var(--glass-border) !important;
  border: 1px solid var(--glass-color) !important;
  color: var(--default-color) !important;
  border-radius: 12px !important;
}

.danger-button {
  background: var(--red-color) !important;
  border: 1px solid var(--red-color) !important;
  color: var(--white) !important;
  border-radius: 12px !important;
}

.danger-button:hover {
  background: transparent !important;
  color: var(--red-color) !important;
}

.undo-button {
  background: var(--error-color) !important;
  border: 1px solid var(--error-color) !important;
  color: var(--white) !important;
  border-radius: 12px !important;
}

.undo-button:hover {
  background: transparent !important;
  color: var(--error-color) !important;
}

/*added because of material-table*/
.sidebar-section .MuiButtonBase-root,
.primary-button,
.reset-button,
.success-button {
  padding: 5px 15px !important;
}

/***********************************  Login page style ******************************/
.login-section .card-header {
  background: var(--white) !important;
  border: none !important;
}

.login-section .card-header img {
  margin-top: -3rem !important;
}

/******************************** Loading modal style ********************************************/
.loading-modal .MuiDialogContent-root {
  border: none !important;
}

.loading-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.loading-modal .css-uhb5lp {
  background: none !important;
  box-shadow: none !important;
}

/***************************** Header page style *******************************************/
.header-section {
  background-color: var(--white) !important;
  width: 100%;
  z-index: 200;
  padding: 10px 10px;
  padding-left: 16px;
  padding-right: 16px;
}

/********************************  side bar page ****************************************************/
.sidebar-section {
  position: relative !important;
  width: 230px;
  overflow: auto;
  height: 100vh;
  margin-top: -17px;
}

/* sidebar  icons */
.sidebar-section .css-cveggr-MuiListItemIcon-root,
.sidebar-section .css-1f8bwsm {
  min-width: 30px !important;
  color: var(--default-color);
}

/****************************** profarma page style ****************************************/

.form-section .card-header {
  background-color: var(--default-bg-color);
  color: var(--default-color);
  border: none !important;
  box-shadow: var(--bs-box-shadow) !important;
}

.form-section .postPerticularData {
  max-height: 300px !important;
  overflow-y: scroll;
}

/********************************** potential vacancy list page style ************************/
.vacancy-list-section .card-header {
  background-color: var(--default-bg-color);
  color: var(--default-color);
  border: none !important;
  box-shadow: var(--bs-box-shadow) !important;
}

.vacancy-list-section .MuiAutocomplete-root label {
  margin-top: -10px !important;
}

/********************************** seniority list page style ************************/
.seniority-list-section .card-header {
  background-color: var(--default-bg-color);
  color: var(--default-color);
  border: none !important;
  box-shadow: var(--bs-box-shadow) !important;
}

.seniority-list-section .MuiAutocomplete-root label {
  margin-top: -10px !important;
}

/*************************  search transfer page style ******************************/
.search-transfer-section .card-header {
  background-color: var(--default-bg-color);
  color: var(--default-color);
  border: none !important;
  box-shadow: var(--bs-box-shadow) !important;
}

/************************** transfer page style **************************************/
.greenDot,
.redDot,
.blueDot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.greenDot {
  background-color: var(--success-color);
}

.redDot {
  background-color: var(--error-color);
}

.blueDot {
  background-color: var(--primary-color);
}

.active-cell:hover {
  padding: 20px 20px 10px 10px;
  font-size: 1.5rem;
  box-shadow: var(--bs-box-shadow) !important;
  cursor: pointer !important;
  text-shadow: 1px 1px 1px var(--default-color);
}

.disable-cell {
  pointer-events: none;
  text-decoration: line-through !important;
  opacity: 0.5;
}

.eligible-table thead {
  --bs-table-bg: var(--glass-border);
  backdrop-filter: blur(100px);
}

/************************** setting page style **************************************/
.setting-section .card-header {
  background-color: var(--default-bg-color);
  color: var(--default-color);
  border: none !important;
  box-shadow: var(--bs-box-shadow) !important;
}

/************************** Welcome Page style *************************************/

.welcome-banner .container-fluid {
  background-image: url("../img/welcome_bg.png");
  height: 100vh !important;
  margin-top: 3rem !important;
  background-size: contain !important;
}
